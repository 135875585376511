<template>
  <div class="header">
    <div class="logoAll" @click="$router.push('./index')" >
      <img :src="logo" class="logo" alt=""/>
      {{name}}
    </div>
    <van-tabs
      class="menu"
      :ellipsis="false"
      color="#a40001"
      :before-change="jumpList"
      title-active-color="#a40001"
      v-model="active"
      :style="{ flex: plat == 119 || plat == 118 ? '0 1 600px' : '0 1 400px' }"
    >
      <van-tab title="首页" name="index"></van-tab>
      <van-tab :title="plat == 121 || plat == 107 ? 'VIP课程' : '基础课程'" name="lesson"></van-tab>
      <!-- <van-tab title="职业能力认证" name="lesson"></van-tab> -->
      <!-- <van-tab
        title="培训"
        name="train"
        v-if="plat == 119 || plat == 118"
      ></van-tab> -->
      <van-tab
        title="短视频"
        name="media"
        
      ></van-tab>
      
      <van-tab title="直播" name="live"></van-tab>
      <!-- <van-tab title="锦囊" name="idea"></van-tab> -->
      <!-- <van-tab title="资源" name="file"></van-tab> -->
      <!-- <van-tab title="大小分子" name="fenzi"></van-tab> -->
      <!-- <van-tab title="证书查询" name="certForm"></van-tab> -->
      <van-tab
        title="家长研修社"
        v-if="plat == 119"
        name="jiazhang03"
      ></van-tab>
      <van-tab
        title="家长研修社"
        v-if="plat == 118"
        name="jiazhang06"
      ></van-tab>
    </van-tabs>
    
    <div class="search" @click="goSearch">
      <input disabled placeholder="请输入您需要搜索的课程"/>
      <div class="btn">
        <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BB%84%202.png"/>
      </div>
    </div>
    
    <div class="login" v-if="!isLogin" @click="$store.commit('changeLoginShow',true)">
      <div>登录</div>
      <div class="login-btn-divider"></div>
      <div>注册</div> 
    </div>
    <van-popover v-model="showPopover" trigger="click">
      <div class="popover">
        <p class="name" >{{myUser && myUser.nickname || "用户昵称"}}</p>
        <div class="study">
            <div @click="jumpList('study')">
              <p>{{studyInfo.today_time}}</p>
              <span>今日学习（分钟）</span>
            </div>
            <p></p>
            <div @click="jumpList('study')">
              <p>{{studyInfo.day_number}}</p>
              <span>连续学习（天）</span>
            </div>
        </div>
        <ul>
          <li @click="jumpList('purchased')"><p>我的已购</p> <i class="el-icon-arrow-right"></i> </li>
        </ul>
        <p @click="logout" class="btn">退出登录</p>
      </div>
      <template #reference>
        <div class="user-info" v-if="isLogin">
          <van-image round fit="cover" lazy-load @error="error"></van-image>
          <img style="width:34px;height:34px;border-radius: 50%;overflow:hidden;border:1px solid #fff" :src="
          myUser && myUser.userface ||
                'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/11fcfa1f-fc2d-47e9-9976-34397b43652a.png'
              " alt="" />
          <span style="margin-left: 10px; color: #333">{{
            myUser.nickname || "用户昵称"
            }}</span>
        </div>
      </template>
    </van-popover>

    <van-popup v-model="show" get-container="body" @click-overlay = "$store.commit('changeLoginShow',false)">
      <div class="login-main">
        <div class="login-title">验证码登录</div>
        <div class="phone-number">
          <van-field
            v-model="phone"
            center
            clearable
            placeholder="请输入手机号"
          >
          </van-field>
        </div>
        <div class="auth-code">
          <van-field v-model="sms" center placeholder="请输入验证码">
            <template #button>
              <div
                class="code-btn"
                @click="sendSms"
                :class="smsNum == 60 ? '' : 'no_touch'"
              >
                {{ smsNum == 60 ? smsText : smsNum + "s后重试" }}
              </div>
            </template>
          </van-field>
        </div>
        <van-button color="#a40001" class="login-btn" @click="login"
          >登录</van-button
        >

        <div class="agreement">
          <p>
            <van-checkbox
              icon-size="14px"
              v-model="checked"
              checked-color="#a40001"
              shape="square"
            >
            登录注册代表您同意</van-checkbox
            >
          </p>
          <p><span>《用户服务协议》</span>和<span>《隐私政策》</span></p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {
      vanSet:false,
      keyword: "",
      phone: "",
      sms: "",
      user: "",
      checked: true,
      smsLoading: false,
      smsTimer: "",
      smsNum: 60,
      smsText: "获取验证码",
      showPopover: false,
      actions: [
        {
          text: "退出登录",
        },
      ],
      loading:null,
    };
  },
  mounted() {
    // if(this.getCookie('user')){
    //   localStorage.setItem("user", this.getCookie('user'));
    //   this.$store.commit('changeLoginShow',false)
    //   this.$store.dispatch('getStudy')
    //   this.$store.dispatch('getVIPInfo')
    // }
    this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  },
  methods: {
        /**取cookie */
    getCookie(objName) {//获取指定名称的cookie的值
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == objName) return unescape(temp[1]);  //解码
      }
      return "";
    },
    error() {
      this.user.userface =
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/pad/amfababy_assess/avatar.png";
    },
    logout() {
      this.showPopover = false

      localStorage.removeItem("user");
      this.$store.commit('changeLoginShow',false)
      this.$store.commit("SET_LOGINSTATUS", false);
    },
    login() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.sms) {
        this.$toast("请输入验证码");
        return;
      }
      console.log(!this.checked);
      if (!this.checked) {
        this.$toast("请勾选服务协议");
        return;
      }
      this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.$api({
        url: "/api/auth/login",
        method: "GET",
        data: {
          mobile: this.phone,
          code: this.sms,
          channel: 8,
        },
      })
        .then((res) => {
          console.log(res);
          this.loading.close();
          if (res.code == 0) {
            this.$toast(res.msg);
            this.clearTimer();

            let { id, mobile, nickname, userface, access_token } = res.data;
            this.user = {
              id,
              mobile,
              nickname,
              userface,
              access_token,
            };

            localStorage.setItem("user", JSON.stringify(this.user));
            // this.setCookie('user', JSON.stringify(this.user))
            this.$store.commit('changeLoginShow',false)
            this.$store.commit("SET_LOGINSTATUS", true);
            this.$store.dispatch('getStudy')
            this.$store.dispatch('getVIPInfo')
          } else {
            
            this.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading.close();
          console.log(err);
        });
    },
  /**存cookie */
    setCookie(objName, objValue, objHours) {
      var str = objName + "=" + escape(objValue); //编码
      if (objHours > 0) {//为0时不设定过期时间，浏览器关闭时cookie自动消失
        var date = new Date();
        var ms = objHours * 3600 * 1000;
        date.setTime(date.getTime() + ms);
        str += "; expires=" + date.toGMTString();
      }
      document.cookie = str;
    },
    sendSms() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.isPhoneNo(this.phone)) {
        this.$toast("手机号格式错误");
        return;
      }
      if(this.smsNum<60) return
      if (this.smsLoading) {
        return;
      }
      this.smsLoading = true;
      this.$api({
        url: "/api/auth/sms",
        method: "POST",
        data: { 
          mobile: this.phone,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$toast(res.msg);
              this.smsNum = this.smsNum - 1;
            this.smsTimer = setInterval(() => {
              this.smsNum = this.smsNum - 1;
              if (this.smsNum == 0) {
                this.smsNum = 60;
                this.clearTimer();
              }
            }, 1000);
          } else {
            this.$toast(res.msg);
          }
            this.smsLoading = false;
        })
        .catch((err) => {
            this.smsLoading = false;
          console.log(err);
        });
    },
    isPhoneNo(phone) {
      var pattern = /^1[3456789]\d{9}$/;
      return pattern.test(phone);
    },
    clearTimer() {
      if (this.smsTimer) {
        clearInterval(this.smsTimer);
        this.smsNum = 60;
      }
    },
    goSearch() {
      this.$router.push("./search");
    },
    jumpList(name) {
      console.log(window.location)
      // if(this.user){
      //   this.setCookie('user', JSON.stringify(this.user))
      // }
      // return
      if (name == "jiazhang03") {
        window.open(
          window.location.origin+window.location.pathname+"#/jiazhang03/index"
        );
        return false;
      } else if (name == "jiazhang06") {
        window.open(
          window.location.origin+window.location.pathname+"#/jiazhang06/index"
        );
        return false;
      } else {
        console.log(name)
        this.$router.push(`./${name}`);
      }
      return true;
    },
  },
  computed: {
    myUser(){
      return this.$store.state.user;
    },
    isLogin(){
      return this.$store.state.isLogin;
    },
    show(){
      return this.$store.state.isLoginShow
    },
    studyInfo(){
      return this.$store.state.studyInfo;
    },
    active: {
      get() {
        return this.$store.state.headerActive;
      },
      set(value) {
        return this.$store.headerActive;
      },
    },
    plat() {
      return this.$store.state.plat_id;
    },
    name() {
      return this.$store.state.plat.name;
    },
    logo() {
      // return this.$store.state.plat.logo;
      return 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png'
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  watch: {

    showPopover(e){
        if(e){
            this.$store.dispatch('getStudy')
        }
    },
    $route(newval ,oldval) {
      console.log('route')
      this.showPopover = false
      if(newval.name=='purchased'){
        console.log('purchased')
        this.$store.commit("SET_HEADER", 'index');
      }else if(newval.name=='certList'){
        console.log('certList')
        this.$store.commit("SET_HEADER", 'certForm');
      }else if(newval.name == 'index' || newval.name == 'train' || newval.name == 'lesson'  || newval.name == 'live' || newval.name == 'media' || newval.name == 'idea' || newval.name == 'certForm'){
        this.$store.commit("SET_HEADER", newval.name);
        console.log('22',newval.name)
      }
    },
  },
};
</script>
<style lang="scss">
  .logoAll{
    word-break: keep-all;
  }
  .user-info{
    span{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      // height: 24px;
      max-width: 80px;
    }
  }
.search{
  flex:1;
  height: 40px;
  border-radius: 8px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;

  input{
    height: 40px;
    border: none;
    line-height: 40px;
    flex:1;
    color: #999999;
    font-size: 16px;
    padding-left: 10px;
    border: 1px solid #A40001;
    border-radius: 8px 0 0 8px;
    box-sizing: border-box;
  }
  .btn{
    border: 1px solid #A40001;
    width: 43px;
    height: 40px;
    background: #A40001;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
  }
  
}

</style>
<style scoped lang="scss">

.menu {
    flex: 0 1 600px;
  }

  .van-tab {
    font-size: 16px;
  }

  .search-input {
    flex: 1;
    height: 40px;
    background: #f9f9f9;
    border: 1px solid #f0f0f0 !important;
    border-radius: 6px;
    box-shadow: none;
    line-height: 40px;
    padding: 0;
    margin-left: 30px;
    cursor: pointer;

    .van-icon {
      margin: 0 10px 0 15px;
    }
  }
  .logoAll{
    color: #0069b5;
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }
  .logo {
    display: block;
    width: 49px;
    // max-width: 151px;
    margin-right: 5px;
    background: #fff;
  }

  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 96px;
    height: 36px;
    margin-right: 20px;
    border: 0.5px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    color: #a40001;
    margin-left: 30px;
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 96px;
    height: 36px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    color: #a40001;
    margin-left: 30px;
  }

  .login-btn-divider {
    width: 1px;
    height: 12px;
    margin: 0 6px;
    background: #a40001;
    opacity: 0.5;
  }
.popover{
  padding: 30px;
  font-size: 14px;
  width: 300px;
  .name{
    font-size: 26px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;
  }
  .study{
    margin: 20px auto;
    display: flex;
    align-items: center;
    div{
      flex:1;
      text-align: center;
      cursor: pointer;
      p{
        font-size: 24px;
      }
      span{
        color: #666;
      }
    }
    >p{
      width: 1px;
      height: 50px;
      background: #e4e4e4;
    }
  }
  ul{
      margin: 20px 0 30px;
    li{
      display: flex;
      background: #f4f4f4;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      align-items: center;
      cursor: pointer;
      p{
        flex: 1;
        font-size: 16px;
      }
    }
  }
  .btn{
    border: 1px solid #e4e4e4;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    width: 90%;
    margin: 20px auto 0;
    text-align: center;
    color: #a40001;
    cursor: pointer;
    border-radius: 20px;
  }
}
</style>