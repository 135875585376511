<template>
  <div :style="{'margin-top': $route.name=='index' && $route.params.plat_type == 'tuoyu' ? '0' : '40px'}" v-show="VIPShow"> 
    <div class="vip" :class="{ vip2: top > height }" v-if="vipInfo">
      <div class="vipMain">
        <p v-if="vipInfo.is_buy">尊敬的会员用户，VIP会员有效期到{{ vipInfo.end_time }}</p>
        <p v-else>开通{{ platName }}VIP会员，畅享精品课程</p>
        <div class="price" v-if="!vipInfo.is_buy">
          <p class="pricePay">现价</p>
          <p class="pricePay">￥</p>
          <p class="pricePay">{{ vipInfo.price_promote }}</p>
          <span>原价￥{{ vipInfo.price }}</span>
        </div>
        <img
          :src="!vipInfo.is_buy ? payBtn : payBtn"
          @click="toPage()"
        />
      </div>
    </div>
    <div class="footer" id="footer">
      <div class="footer-content">
        <img
          :src="logo"
        />
        <p style="font-weight: bold">
          联系我们<br />
          <span style="font-weight: normal">客服电话：13811674685</span>
        </p>
        <div class="ma" v-if="$route.params.plat_type &&
          $route.params.plat_type == 'yingyou'">
          <p>
            <img
              src="@/assets/images/yingyouMa.jpg"
            />
            微信客服
          </p>
        </div>
        <div class="ma" v-else-if="$route.params.plat_type &&
          $route.params.plat_type == 'tuoyu'">
          <p>
            <img
              src="@/assets/images/tuoyuMa.jpg"
            />
            京学托育服务
          </p>
        </div>
        <div class="ma" v-else>
          <p>
            <img
              src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/ma1.jpg"
            />
            微信公众号
          </p>
        </div>
      </div>  
      <!-- |  京公网安备 11010502042303号 -->
      <p v-if=" $route.params.plat_type !== 'zhongke' ">京ICP备19026963号-13  |  Copyright ©{{ $route.params.plat_type == 'tuoyu' || $route.params.plat_type == 'yingyou' ? $route.params.plat_type+'gaoyanyuan': 'jiazhangyanxiushe'}}</p>
      <p v-else>京ICP备19026963号-13  |  Copyright ©jingxuezhongkegaoyanyuan </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      payBtn:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_btn.jpg',
      payBtn2:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_btn2.png',
      height: 0,
      top: 0,
    };
  },
  computed: {
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    VIPShow(){
      return this.$store.state.VIPShow
    },
    logo() {
      return this.$store.state.plat.logo;
      // return "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png";
    },
  },
  mounted() {
    this.height = document.documentElement.clientHeight;
    this.handleScrollToc();
    window.addEventListener("scroll", this.handleScrollToc, true);
    this.getVip();
  },
  methods: {
    async getVip() {
      let res = await this.$api({
        method: "get",
        url: "/api/platform/university/index",
        data: {
          flat_id: this.plat,
          is_interior: 2,
          terminal: 1, //1 pc 2 小程序
        },
      });
      let vipInfo = res.data.plat_vip;
      this.$store.commit("SET_VIP", vipInfo);
    },
    toPage() {
      if (!localStorage.getItem("user")) {
        this.$store.commit("changeLoginShow", true);
      } else {
        this.$router.push({
          path: "./detailVip",
          query: {
            id: this.vipInfo.goods_id,
          },
        });
      }
    },
    handleScrollToc() {
      let info = document.getElementById("footer").getBoundingClientRect();
      this.top = info.top;
      // console.log('VIPShow',this.VIPShow);
      // console.log('height',this.height);
      // console.log('top',this.top);
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  color: #333333;
  display: block;
  background: #f1f1f1;
  height: 214px;
  .footer-content {
    height: 182px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    > img {
      width: auto;
      max-width: 200px;
    }
    > p {
      text-align: left;
      line-height: 30px;
      font-size: 16px;
      span {
        color: #666666;
      }
    }
    .ma {
      font-size: 12px;
      color: #666666;
      p {
        margin-right: 20px;
        margin-left: 43px;
        display: inline-block;
        img {
          display: block;
          margin-bottom: 5px;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  > p {
    width: 100%;
    background: #e2e2e2;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    margin: 0;
  }
}
.vip {
  display: none;
  background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_bg.jpg")
    center no-repeat;
  width: 100%;
  height: 64px;

  .vipMain {
    height: 100%;
    width: 1200px;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0 auto;
    > p {
      flex: 1;
      color: #222222;
      font-size: 24px;
      text-align: left;
    }
    .price {
      display: flex;
      align-items: center;
      padding-right: 56px;
      p {
        font-weight: bold;

        &:first-of-type {
          font-size: 23px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #201a10;
          // line-height: 20px;
          // text-shadow: 2px 0px 0px #921C11;

          background: linear-gradient(-42deg, #201a10 0%, #615444 35%, #262316 84%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          filter: drop-shadow(2px 0px 0px #fff8f1);
        }
        &:nth-of-type(2) {
          font-size: 14px;
          font-family: Source Han Sans CN;
          color: #efcf9c;
          line-height: 28px;
          // filter: 0drop-shadow(2px 0px 0px #921C11);
          align-self: start;
          padding-left: 8px;
          background: linear-gradient(-42deg, #201a10 0%, #615444 35%, #262316 84%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &:nth-of-type(3) {
          font-size: 40px;
          color: #fcebde;

          background: linear-gradient(-42deg, #e94a30 0%, #ff5943 55%, #e15038 100%);
          filter: drop-shadow(2px 0px 0px #fff8f1);

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      span {
        font-size: 22px;
        font-weight: 400;
        text-decoration: line-through;
        color: #6d6652;
        display: block;
        padding-left: 25px;
        display: none;
      }
    }
    img {
      cursor: pointer;
    }
  }
}
.vip2 {
  bottom: 0;
  left: 0;
  z-index: 100;
  position: fixed;
}
</style>
