import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { MessageBox } from 'element-ui';
import store from '@/store/' 
Vue.use(store)

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const baseRoutes = [
  {
    path: '/:plat_type/index',
    name: 'index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/:plat_type/index2',
    name: 'index2',
    component: () => import('@/views/Index2.vue'),
  },
  {
    path: '/:plat_type/molecule',
    name: 'molecule',
    component: () => import('@/views/Molecule.vue'),
  },
  {
    path: '/:plat_type/idea',
    name: 'idea',
    component: () => import('@/views/Idea.vue'),
  },
  {
    path: '/:plat_type/ideaDetail',
    name: 'ideaDetail',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/IdeaDetail.vue'),
  },
  {
    path: '/:plat_type/lesson',
    name: 'lesson',
    component: () => import('@/views/Lesson.vue'),
  },
  {
    path: '/:plat_type/lessonTuoyu',
    name: 'lessonTuoyu',
    component: () => import('@/views/LessonTuoyu.vue'),
  },
  {
    path: '/:plat_type/cert',
    name: 'cert',
    component: () => import('@/views/Cert.vue'),
  },
  {
    path: '/:plat_type/search',
    name: 'search',
    component: () => import('@/views/Search.vue'),
  },
  {
    path: '/:plat_type/search2',
    name: 'search',
    component: () => import('@/views/Search2.vue'),
  },
  {
    path: '/:plat_type/live',
    name: 'live',
    component: () => import('@/views/Live.vue'),
  },
  {
    path: '/:plat_type/liveTuoyu',
    name: 'liveTuoyu',
    component: () => import('@/views/LiveTuoyu.vue'),
  },
  {
    path: '/:plat_type/media',
    name: 'media',
    component: () => import('@/views/Media.vue'),
  },
  {
    path: '/:plat_type/detail',
    name: 'detail',
    // meta: { 
    //   requireAuth: true

    //  },
    component: () => import('@/views/Detail.vue'),
  },
  {
    path: '/:plat_type/detail2',
    name: 'detail2',
    // meta: { 
    //   requireAuth: true

    //  },
    component: () => import('@/views/Detail2.vue'),
  },
  {
    path: '/:plat_type/detailVip',
    name: 'detailVip',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/DetailVip.vue'),
  },
  {
    path: '/:plat_type/train',
    name: 'train',
    component: () => import('@/views/Train.vue'),
  },
  {
    path: '/:plat_type/player',
    name: 'player',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/Player.vue'),
  },
  {
    path: '/:plat_type/player2',
    name: 'player2',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/Player2.vue'),
  },
  {
    path: '/:plat_type/purchased',
    name: 'purchased',
    component: () => import('@/views/Purchased.vue'),
  },
  {
    path: '/:plat_type/purchased2',
    name: 'purchased2',
    component: () => import('@/views/Purchased2.vue'),
  },
  {
    path: '/:plat_type/study',
    name: 'study',
    component: () => import('@/views/Study.vue'),
  },
  {
    path: '/:plat_type/study2',
    name: 'study2',
    component: () => import('@/views/Study2.vue'),
  },
  {
    path: '/:plat_type/examin',
    name: 'examin',
    component: () => import('@/views/Examin.vue'),
  },
  {
    path: '/:plat_type/examinList',
    name: 'examinList',
    component: () => import('@/views/examin/ExaminList.vue'),
  },
  {
    path: '/:plat_type/examinResult',
    name: 'examinResult',
    component: () => import('@/views/ExaminResult.vue'),
  },
  {
    path: '/:plat_type/examinResultDetail',
    name: 'examinResultDetail',
    component: () => import('@/views/ExaminResultDetail.vue'),
  },
  {
    path: '/:plat_type/certSave',
    name: 'certSave',
    component: () => import('@/views/CertSave.vue'),
  },
  {
    path: '/:plat_type/certForm',
    name: 'certForm',
    component: () => import('@/views/CertForm.vue'),
  },
  {
    path: '/:plat_type/certList',
    name: 'certList',
    component: () => import('@/views/CertList.vue'),
  },
  {
    path: '/:plat_type/demonstration',
    name: 'demonstration',
    component: () => import('@/views/Demonstration.vue'),
  },
  {
    path: '/:plat_type/orderList',
    name: 'orderList',
    component: () => import('@/views/OrderList.vue'),
  },
  {
    path: '/:plat_type/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/OrderDetail.vue'),
  },
  {
    path: '/:plat_type/myCenter',
    name: 'myCenter',
    component: () => import('@/views/MyCenter.vue'),
  },
  {
    path: '/:plat_type/exchange',
    name: 'exchange',
    component: () => import('@/views/Exchange.vue'),
  },
  {
    path: '/:plat_type/vipLesson',
    name: 'vipLesson',
    component: () => import('@/views/VipLesson.vue'),
  },
  {
    path: "*",
    name: "404",
    redirct: "/404",
    component: () => import("@/views/NotFound.vue"),
    hidden: true
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    console.log('111')
    window.scrollTo(0, 0);
  },
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: baseRoutes
});

function is_weixin() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const user = localStorage.getItem('user');
  if (user || !to.meta.requireAuth) {
    next()
    NProgress.done();
  } else {
    console.log('禁止查看')
    store.commit('changeLoginShow',true)
    // MessageBox.confirm('请登录后查看1', '提示', {
    //   confirmButtonText: '确定',
    //   cancelButtonText: '取消',
    // }).then(() => {
    //    store.commit('changeLoginShow',true)
    // }).catch(() => {
              
    // });
    // next()
    // NProgress.done();
  }
})

export { router, baseRoutes }
