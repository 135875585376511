import api from './api'
import qs from 'qs'
import store from '../store/index'
import { Toast } from 'vant';
// import { resolve } from 'core-js/fn/promise'
const  request = {
   // 手机号登录（sass转过来免登录）
   mobileLogin(data){
      return new Promise(resolve=>{
          api({
           method:'post',
           url:'/api/auth/platform/login',
           data:qs.stringify(data)
          }).then(res=>{
           console.log(res)
           if(res.code==0) {
              resolve(res.data)
           }else{
              Toast(res.msg)
           }
           
          })
       })
  },
    // 证书验证码
    certSms(data){
        return new Promise(resolve=>{
            api({
             method:'post',
             url:'/api/auth/cert/sms',
             data:qs.stringify({
                mobile:data
             })
            }).then(res=>{
             console.log(res)
             if(res.code==0) {
                Toast(res.msg)
                resolve(res)
             }else{
                Toast(res.msg)
             }
             
            })
         })
    },
    // 证书查询
    certSearch(data){
        let toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
        return new Promise(resolve=>{
            api({
             method:'post',
             url:'/api/flat/cert/list',
             data:qs.stringify(data),
            }).then(res=>{
             toast.clear();
             console.log(res)
             resolve(res)
             
            })
         })
    },
    // 证书查询2
    certSearch2(data){
      let toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        });
      return new Promise(resolve=>{
          api({
           method:'post',
           url:'/api/flat/change/list',
           data:qs.stringify(data),
          }).then(res=>{
           toast.clear();
           console.log(res)
           resolve(res)
           
          })
       })
  },
   // 托育
   // 托育已购接口
   tuoyuBuy(data){
      let toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        });
      return new Promise(resolve=>{
          api({
           method:'get',
           url:'/api/users/platform/goods',
           data:data,
          }).then(res=>{
           toast.clear();
           console.log(res)
           resolve(res)
           
          })
       })
  },
  // 托育学习中心
  tuoyuStudy(data){
   let toast = Toast.loading({
       message: '加载中...',
       forbidClick: true,
       loadingType: 'spinner',
     });
   return new Promise(resolve=>{
       api({
        method:'post',
        url:'/api/flatform/record/list',
        data:data,
       }).then(res=>{
        toast.clear();
        resolve(res)
        
       })
    })
   },
   // 托育埋点记录
   tuoyuRecord(data){
      
      return new Promise(resolve=>{
         api({
         method:'post',
         url:'/api/users/flatform/record',
         data:qs.stringify(data),
         }).then(res=>{
         resolve(res)
         })
      })
   },
   // 托育分类（全部）
   tuoyuCategory(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/plat/category',
         data:data,
         }).then(res=>{
         resolve(res)
         })
      })
   },
   // 托育分类（没有全部）
   tuoyuCategory2(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/plat/not/category',
         data:data,
         }).then(res=>{
         resolve(res)
         })
      })
   },
   // 托育首页
   tuoyuIndex(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/platform/university/index',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 课程详情
   tuoyuDetail(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/institute/course/info/get',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 随堂测试题目
   tuoyuExamin(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/institute/course/quizzes/get',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 提交答案
   tuoyuExaminSubmit(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/institute/course/commit/answer',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 推荐书籍
   tuoyuDetailBooks(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/institute/course/book/commend',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 课程列表
   tuoyuLessonList(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/plat/category/list',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 热门列表
   tuoyuHotList(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/platform/hot/List',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 搜索
   tuoyuSearch(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/users/flat/changeList',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 提交用户考试信息
   userInfoSubmit(data){
      return new Promise(resolve=>{
         api({
         method:'post',
         url:'/api/flat/user/info',
         data:qs.stringify(data),
         }).then(res=>{
            resolve(res)
         })
      })
   },
   //商品考试列表
   detailExaminList(id){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/goods/test/list',
         data:{discipline_id:id},
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 上传图片
   toUpload(data){
      return new Promise(resolve=>{
         api({
         method:'post',
         url:'/api/platform/test/photo',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 证书信息
   certInfo(id){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/goods/cert/info',
         data:{discipline_id:id},
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 个人信息
   userInfo(){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/plat/user/info',
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 订单列表
   orderList(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/plat/user/order',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 订单详情
   orderDetail(orderno){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/plat/order/detail',
         data:{orderno: orderno},
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 删除订单、取消订单（更改订单状态的）
   orderDel(orderno,status){
      return new Promise(resolve=>{
         api({
         method:'post',
         url:'/api/plat/order/status',
         data:qs.stringify({orderno: orderno, status: status}),
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 支付订单
   orderPay(orderno){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/order/flat/pay',
         data:{orderno: orderno,  pay_type: 12},
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 分片上传视频
   toUploadVideoFen(data){
      return new Promise(resolve=>{
         api({
         method:'post',
         url:'/api/platform/fen/video',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 上传视频
   toUploadVideo(data){
      return new Promise(resolve=>{
         api({
         method:'post',
         url:'/api/platform/test/video',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 实操课程视频上传后更新
   videoExamin(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/chapter/video',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 课程兑换
   exchangeLesson(data){
      return new Promise(resolve=>{
          api({
              method:'get',
              url:'/api/goods/platform/exchange',
              data,
              loading:true,
          }).then(res=>{
              if(res.code==0){
                  if(res.data.status==0)resolve(res.data)
                  else Toast(res.data.message)
              } else {
                  Toast(res.msg)
              }

          })
      })
  },
  // 家长研修社06首页
  jiazhangIndex(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/platform/parent/index',
         data:data,
         }).then(res=>{
            resolve(res)
         })
      })
   },
     // 家长研修社03首页
  jiazhangIndex03(data){
   return new Promise(resolve=>{
      api({
      method:'get',
      url:'/api/yu/index',
      data:data,
      }).then(res=>{
         resolve(res)
      })
   })
},
jiazhangIndex032(data){
   return new Promise(resolve=>{
      api({
      method:'get',
      url:'/api/plat/parent/index?flat_id=107&is_interior=2&terminal=1',
      }).then(res=>{
         resolve(res)
      })
   })
},

   // 家长研修社06VIP分类
  jiazhangVIP(){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/goods/year/category',
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 证书列表
  certList(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/users/cert/list',
         data:data
         }).then(res=>{
            resolve(res)
         })
      })
   },
   // 用于单点登录
  userOnly(data){
      return new Promise(resolve=>{
         api({
         method:'get',
         url:'/api/users/only',
         data:data
         }).then(res=>{
            resolve(res)
         })
      })
   },
}
export default request