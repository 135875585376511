var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.VIPShow),expression:"VIPShow"}],style:({'margin-top': _vm.$route.name=='index' && _vm.$route.params.plat_type == 'tuoyu' ? '0' : '40px'})},[(_vm.vipInfo)?_c('div',{staticClass:"vip",class:{ vip2: _vm.top > _vm.height }},[_c('div',{staticClass:"vipMain"},[(_vm.vipInfo.is_buy)?_c('p',[_vm._v("尊敬的会员用户，VIP会员有效期到"+_vm._s(_vm.vipInfo.end_time))]):_c('p',[_vm._v("开通"+_vm._s(_vm.platName)+"VIP会员，畅享精品课程")]),(!_vm.vipInfo.is_buy)?_c('div',{staticClass:"price"},[_c('p',{staticClass:"pricePay"},[_vm._v("现价")]),_c('p',{staticClass:"pricePay"},[_vm._v("￥")]),_c('p',{staticClass:"pricePay"},[_vm._v(_vm._s(_vm.vipInfo.price_promote))]),_c('span',[_vm._v("原价￥"+_vm._s(_vm.vipInfo.price))])]):_vm._e(),_c('img',{attrs:{"src":!_vm.vipInfo.is_buy ? _vm.payBtn : _vm.payBtn},on:{"click":function($event){return _vm.toPage()}}})])]):_vm._e(),_c('div',{staticClass:"footer",attrs:{"id":"footer"}},[_c('div',{staticClass:"footer-content"},[_c('img',{attrs:{"src":_vm.logo}}),_vm._m(0),(_vm.$route.params.plat_type &&
          _vm.$route.params.plat_type == 'yingyou')?_c('div',{staticClass:"ma"},[_vm._m(1)]):(_vm.$route.params.plat_type &&
          _vm.$route.params.plat_type == 'tuoyu')?_c('div',{staticClass:"ma"},[_vm._m(2)]):_c('div',{staticClass:"ma"},[_vm._m(3)])]),( _vm.$route.params.plat_type !== 'zhongke' )?_c('p',[_vm._v("京ICP备19026963号-13 | Copyright ©"+_vm._s(_vm.$route.params.plat_type == 'tuoyu' || _vm.$route.params.plat_type == 'yingyou' ? _vm.$route.params.plat_type+'gaoyanyuan': 'jiazhangyanxiushe'))]):_c('p',[_vm._v("京ICP备19026963号-13 | Copyright ©jingxuezhongkegaoyanyuan ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" 联系我们"),_c('br'),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("客服电话：13811674685")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/images/yingyouMa.jpg")}}),_vm._v(" 微信客服 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/images/tuoyuMa.jpg")}}),_vm._v(" 京学托育服务 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":"https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/ma1.jpg"}}),_vm._v(" 微信公众号 ")])
}]

export { render, staticRenderFns }