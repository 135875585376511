var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(
        _vm.$route.name != 'search' &&
        (_vm.$route.params.plat_type == 'tuoyu' || _vm.$route.params.plat_type == 'yingyou' || _vm.$route.params.plat_type == 'zhongke' )
      )?_c('Header2'):(
        _vm.$route.name != 'search' &&
         _vm.$route.params.plat_type == 'jiazhang06'
      )?_c('Header3'):(
        _vm.$route.name != 'search' &&  _vm.$route.params.plat_type == 'jiazhang03')?_c('Header03'):(
        _vm.$route.name != 'search' && _vm.$route.params.plat_type)?_c('Header'):_vm._e(),_c('div',{ref:"appContent",staticClass:"appContent",class:{whiteBg:_vm.isWhiteBg}},[_c('div',{staticClass:"main",class:{
          vipMain: _vm.$route.name == 'detailVip',
          searchMain: _vm.$route.name == 'search',
          tuoyu:
            _vm.$route.params.plat_type == 'tuoyu' || _vm.$route.params.plat_type == 'yingyou' || _vm.$route.params.plat_type == 'jiazhang06' || _vm.$route.params.plat_type == 'zhongke' || _vm.$route.params.plat_type == 'jiazhang03',
        }},[_c('router-view')],1),(_vm.$route.params.plat_type)?_c('Footer2'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }