const vuexInit = {
    platMap: {
        'tuoyu': {
         priceHide: true,   
        id: 119,
        name: "托育高研院",
        logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/tuoyuLogo.png',
        faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_tuoyu.ico',
        indexData: [{
            category_name: "园所活动",
        },
        {
            category_name: "游戏方案",
        },
        {
            category_name: "环境创设",
        },
        {
            category_name: "手工活动",
        },
        {
            category_name: "音乐",
        },
        {
            category_name: "食谱",
        },
        {
            category_name: "绘本",
        },
        {
            category_name: "特色课程",
        },
        {
            category_name: "体育游戏",
        },
        {
            category_name: "全部分类",
        },
        ],
        },
        'youjiao': {
        id: 118,
        name: "幼教高研院",
        logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/youjiaoLogo.png',
        faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_jingxue.ico',
        indexData: [{
            category_name: "园所活动",
        },
        {
            category_name: "游戏方案",
        },
        {
            category_name: "环境创设",
        },
        {
            category_name: "手工活动",
        },
        {
            category_name: "音乐",
        },
        {
            category_name: "食谱",
        },
        {
            category_name: "绘本",
        },
        {
            category_name: "特色课程",
        },
        {
            category_name: "体育游戏",
        },
        {
            category_name: "全部分类",
        },
        ],
        },
        'yingyou': {
        id: 171,
        name: "婴幼高研院",
        logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/youjiaoLogo.png',
        faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_tuoyu.ico'
        },
        'jiazhang03': {
        id: 121,
        name: "家长学堂",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuojiazhangLogo5.png',
            // faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_chongdong.ico',
            faviconurl: 'none'
        },
        'jiazhang06': { //改成0-6岁家长研修社
        id: 107,
        name: "家长学堂",
        logo: 'https://cdnsaas.amfakids.cn/miniprogram/study_center/tuoyu2/logo06_bak.png',
        // faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_chongdong.ico',
        faviconurl: 'none'
        },
        "zhongke": {
            id: 205,
            name: "中社科高研院",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/logo.png',
            faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_chongdong.ico'
        }
    }
  }
  export default vuexInit